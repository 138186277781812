<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4" v-html="message">
      </v-card-text>
      <v-card-text class="text-center">
        <v-select v-model="espacios" :items="esps" :item-text="item => item.title" :item-value="item => item.val" multiple chips>
          <template v-slot:selection="{ attrs, item, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click:close="espacios = espacios.filter(es => es != item.val)"
            >
              <strong>{{ item.title }}</strong>
            </v-chip>
          </template>
        </v-select>
        <v-checkbox v-model="autoDupe" :label="'DUPLICAR TAMBIÉN A '+ (esps.filter(es => es.val == e).length > 0 ? esps.filter(es => es.val == e)[0].title : '')"></v-checkbox>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="agree">Duplicar</v-btn>
        <v-btn color="grey" text @click.native="cancel">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["esps", "e"],
  data: () => ({
    espacios: [],
    dialog: false,
    autoDupe: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 490,
      zIndex: 200
    }
  }),
  methods: {
    open(title, message) {
      this.dialog = true
      this.title = title
      this.message = message
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      if(this.autoDupe) this.espacios = [...new Set([...this.espacios,this.e])]
      else this.espacios = this.espacios.filter(es => es != this.e)
      this.resolve(this.espacios)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>